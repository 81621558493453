body,
html,
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-y: auto;
  height: 100%;
}

/* .makeStyles-paper-11, */
/* .makeStyles-paper-11 h2, */
/* .MuiTypography-body1,
.MuiPaper-root, */
h2 {
  color: #f9d749 !important;
}

body {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center center !important;
  background-attachment: fixed !important;
  background-size: cover !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rubik', cursive !important;
  text-transform: uppercase;
  color: #f9d749;
}

.MuiToolbar-regular {
  color: #e0e3bd;
}

.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover,
.MuiListItem-button:hover,
.MuiButtonBase-root:hover {
  color: #fff !important;
}

code {
  font-family: 'Rubik', monospace;
}

.wallet-button {
  color: white;
  font-family: 'Rubik', sans-serif !important;
  font-size: 1.2rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}
.wallet-button:hover {
  color: #f9d749 !important;
}

.navLink {
  color: white !important;
}

.navLink:hover {
  color: #f9d749 !important;
}

.shinyButton {
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 15px;
  font-size: 1rem !important;
  padding-left: 5px;
  color: #f9d749 !important;
  box-shadow: 0px 0px 5px 0px rgba(251, 221, 76, 1);
}

.shinyButtonGreen {
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 15px;
  font-size: 1rem !important;
  padding-left: 5px;
  color: #00ff00 !important;
  box-shadow: 0px 0px 5px 0px rgb(0, 255, 0);
}

.shinyButtonDisabled {
  border-radius: 15px;

  color: grey !important;
  box-shadow: 0px 0px 5px 0px grey;
}

.shinyButton:hover {
  background-color: #f9d749 !important;
  color: #171923 !important;
}

.shinyButtonGreen:hover {
  background-color: #00ff00 !important;
  color: #171923 !important;
}

.shinyButtonSecondary {
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 15px;
  font-size: 1rem !important;
  padding-left: 5px;
  background-color: #f9d749 !important;
  color: #171923 !important;
  /* box-shadow: 0px 0px 5px 0px rgba(251,221,76,1); */
}

.account {
  display: flex;
  align-items: center;
}

.account > img,
.account > div {
  margin-right: 8px;
}

.shinyButtonDisabledSecondary {
  border-radius: 15px;

  color: grey !important;
  box-shadow: 0px 0px 5px 0px grey;
}

.shinyButtonSecondary:hover {
  background-color: #f9d749 !important;
  color: #171923 !important;
  box-shadow: 0px 0px 5px 0px rgba(251, 221, 76, 1);
}

.navTokenPrice {
  padding-top: 5px;
  padding-right: 15px;
  color: #fff;
}

.navTokenIcon {
  height: 30px !important;
  width: 30px !important;
  padding-right: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  overflow: hidden;
}

/* Dropdown menus implementation */
.navDropdownMenuRoot:after{
  content: '';
  border: 4px solid transparent;
  border-top: 4px solid white;
  margin-left: 5px;
  margin-bottom: 3px;
  display: inline-block;
  vertical-align: bottom;
}

.navDropdownMenu .navDropdownMenuContainer {
  display: none;
  padding-top: 24px;
  position: absolute;
  left: 0;
  top: 20px;
  animation: rotateMenu 400ms ease-in-out forwards;
  transform-origin: top center;
}

.navDropdownMenu:hover .navDropdownMenuContainer {
  display: block;
}

.navDropdownMenu .navDropdownMenuWrapper {
  background-color: #171923;
  border: 1px solid #171923;
  border-bottom: none;
}

.navDropdownMenu .navDropdownMenuItem {
  border-bottom: 1px solid rgb(255, 215, 2);
}

.navDropdownMenu .navDropdownMenuItem:last-of-type {
  border-bottom: 1px solid #171923;
}

.navDropdownMenu .navDropdownMenuItem a {
  display: block;
  padding-bottom: 5px;
  padding-right: 10px;
  font-size: 15px;
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg)
  }
  70% {
    transform: rotateX(20deg)
  }
  100% {
    transform: rotateX(0deg)
  }
}

/* End dropdown menus implementation */

.bomb {
  background-image: url('/bomb1.png');
}

.bshare {
  background-image: url('/bomb2.png');
}

.btc {
  background-image: url('/bomb3.png');
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.modal__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.19);
  backdrop-filter: blur(6px);
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  z-index: 100;
}

.modal__align {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modal__content {
  width: 800px;
  height: 500px;
  box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07), 0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
    0px 7.779px 7.30492px rgba(0, 0, 0, 0.035), 0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
  border-radius: 20px;
  background: transparent;
  color: #000;
  margin: 0rem 4rem;
}

.modal__close {
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  bottom: 50px;
  width: 32px;
  height: 32px;
  padding: 0;
}

.modal__video-align {
  display: flex;
  position: relative;
  bottom: 37px;
}

.modal__video-style {
  border-radius: 20px;
  z-index: 100;
}

.modal__spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal__spinner {
  animation: spin 2s linear infinite;
  font-size: 40px;
  color: #1b6aae;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 800px) {
  .modal__content {
    margin: 0rem 1rem;
    width: 100%;
  }
  .modal__video-style {
    width: 100%;
  }
}

@media screen and (max-width: 499px) {
  .modal__content {
    background: transparent;
    height: auto;
  }
  .modal__video-align {
    bottom: 0px;
  }
  .modal__video-style {
    height: auto;
  }
}
